export default {
  'title': 'Website Check',
  'new': 'Create website check Task',
  'form_info': 'Please check and complete the form information to meet the necessary validation standards. Filling in all \'non-required fields (without *)\' will help us obtain audit results faster and more accurately.',
  'brand_names': 'Brand Information',
  'brand_names_tip': 'Enter your brand name or the brand name of the product being sold',
  'product_details_page_link': 'Product Details Page URL',
  'product_details_page_link_tip': 'Please enter a URL starting with http or https. If there is no product details page, please enter the homepage URL',
  'product_list_page_link': 'Product List Page URL',
  'product_list_page_link_tip': 'Please enter a URL starting with http or https. If there is no product list page, please enter the homepage URL',
  'site_link': 'Homepage URL',
  'site_link_tip': 'Please enter a URL starting with http or https, for example https://truemetrics.cn',
  'has_gdpr_regions': 'Already Implemented or Planning to Target EU Countries',
  'has_shopping_ads': 'Does the website have a shopping cart or support online transactions',
  'about_us_page_link': 'About Us Page URL',
  'contact_us_page_link': 'Contact Us Page URL',
  'gdpr_page_link': 'GDPR Information Page URL',
  'gdpr_page_link_tip': 'Please enter a URL starting with http or https, compliant with GDPR data security and Cookie management and application information page URL',
  'pay_page_link': 'Payment Information Page URL',
  'privacy_page_link': 'Privacy Information Page URL',
  'privacy_page_link_tip': 'For example, privacy policy, please enter a URL starting with http or https',
  'refund_page_link': 'Refund Policy Page URL',
  'return_page_link': 'Return Policy Page URL',
  'shipping_page_link': 'Shipping Policy Page URL',
  'task_status': 'Status',
  'task_status_true': 'Completed',
  'task_status_false': 'In Progress',
  'site_origin': 'Website Domain',
  'create_success': 'Task created successfully, please wait for the scan to complete',
  'btn_download': 'Download Report PDF',
  'page_detail': {
    'website_features': 'Website Features',
    'privacy_security': 'Privacy and Security',
    'website_performance_experience': 'Website Performance and Experience',
    'shopping_ad_risk_check': 'Shopping Ad Risk Check',
    'summary_1': 'All mandatory requirements have been successfully checked!',
    'summary_2': 'Please self-audit. If not applicable or if requirements are met upon self-audit, you may decide to go live at your discretion.',
    'summary_3': 'Please review items that need fixing. Submit for reevaluation after fixing the items!',
    'disclaime': 'Disclaimer:',
    'disclaime_desc': 'This tool does not provide comments or evaluation advice on compliance with Google ad policies. Thank you for your understanding! Google ad policies are subject to periodic updates. Please refer to the index link for the most accurate official information.',
    'item': 'Item',
    'items': 'Items',
    'report_overview': 'Report Overview',
    'evaluation_results': 'Evaluation Results',
    'no_risk': 'No Risk',
    'risk_identified': 'Risk Identified',
    'audit_results': 'Audit Results',
    'audit_items': 'Audit Items',
    'reference_links': 'Link',
    'attachments': 'Attachments',
    'website_error_details': 'Website Error Details',
    'mandatory_requirements': 'Mandatory Requirements',
    'suggested_requirements': 'Suggested Requirements (Does not affect overall score)',
    'tip': 'Tip'
  },
  'report_data': {
    'id': 'Report ID',
    'summary_score': 'Score',
    'note': 'Passing Rate',
    'task_info': 'Task ID',
    'create_time': 'Create Time'
  },
  'detail_title': 'Website audit results report',
  'report_checks': {
    'has_cta': 'Does the product detail page have a call-to-action button?',
    'has_cta_tip': 'Recommend highlighting the call-to-action button.',
    'has_concat_us_page': 'Is there a \'Contact Us\' page provided?',
    'has_concat_us_page_tip': 'Suggest providing information on how to contact the company.',
    'has_phone': 'Does the \'Contact Us\' page provide a contact phone number for China?',
    'has_phone_tip': 'Recommend providing a contact phone number for China.',
    'has_email': 'Does the \'Contact Us\' page provide an email address for contact?',
    'has_email_tip': 'Suggest providing an email address for contact.',
    'has_cn_address': 'Does the \'Contact Us\' page provide a contact address for China?',
    'has_cn_address_tip': 'Recommend providing a contact address.',
    'has_about_page': 'Is there an \'About Us\' page provided?',
    'has_about_page_tip': 'Suggest providing an \'About Us\' page.',
    'about_year': 'Does the \'About Us\' page provide the year the company was founded?',
    'about_year_tip': 'Recommend providing the valid year of establishment for the company.',
    'has_about_country': 'Does the \'About Us\' page provide the country where the company operates?',
    'has_about_country_tip': 'Suggest providing the actual country where the company operates.',
    'has_search': 'Does the homepage provide a search function?',
    'has_search_tip': 'Recommend supporting an on-site search function.',
    'has_filter': 'Does the product list page provide a filtering function?',
    'has_filter_tip': 'Recommend supporting product filtering function.',
    'has_sort': 'Does the product list page provide a sorting function?',
    'has_sort_tip': 'Recommend supporting product sorting function.',
    'has_return_page': 'Is there a return and exchange policy page provided?',
    'has_return_page_tip': 'Suggest providing a return and exchange policy.',
    'has_return_contact': 'Does the return and exchange page provide a return address or contact information?',
    'has_return_contact_tip': 'Recommend providing return and exchange contact information.',
    'has_return_shipping': 'Does the return and exchange page provide information on return shipping?',
    'has_return_shipping_tip': 'Recommend providing information on return shipping.',
    'has_return_freight_carrier': 'Does the return and exchange page specify who bears the cost of return shipping?',
    'has_return_freight_carrier_tip': 'Recommend specifying who bears the cost of return shipping.',
    'has_shipping_page': 'Does the shipping information page provide information on shipping fees?',
    'has_shipping_page_tip': 'Recommend providing information on shipping fees.',
    'has_freight_desc': 'Does the shipping information page provide conditions for free shipping?',
    'has_freight_desc_tip': 'Recommend providing conditions for free shipping.',
    'has_shipping_desc': 'Does the shipping information page provide information on shipping costs and times for different countries or regions?',
    'has_shipping_desc_tip': 'Recommend providing shipping cost information for different countries or regions.',
    'has_order_shipping_time': 'Does the shipping information page provide information on order processing and shipping times?',
    'has_order_shipping_time_tip': 'Recommend providing information on order processing and shipping times.',
    'has_payment_page': 'Does the payment information page provide payment instructions?',
    'has_payment_page_tip': 'Suggest providing a payment instructions page.',
    'has_payment': 'Does the bottom of the homepage provide payment method icons?',
    'has_payment_tip': 'Recommend supplementing payment method icons at the bottom of the homepage.',
    'has_sales': 'Does the website contain promotional content?',
    'has_sales_tip': 'Please avoid the use of misleading or unrealistic promotional content.',
    'has_infringement_imgs': 'Does the website have a risk of infringing on copyrighted materials?',
    'has_infringement_imgs_tip': 'Random checks show high similarity with external resources in some images. Refer to the report appendix for details, manually verify to avoid infringement risks.',
    'has_social': 'Do the social media tags on the homepage open correctly?',
    'has_social_tip': 'There are social links but none of them open correctly.',
    'has_privacy_page': 'Is there a privacy protection policy page provided?',
    'has_privacy_page_tip': 'Suggest providing a privacy protection policy.',
    'has_gdpr_management_page': 'Is there explicit visitor consent management?',
    'has_gdpr_management_page_tip': 'Recommend adding visitor consent management functionality.',
    'has_privacy_concat': 'Does the privacy policy page provide company contact information?',
    'has_privacy_concat_tip': 'Suggest providing company contact information on the privacy protection policy page.',
    'is_ssl': 'Does the website use SSL protocol?',
    'is_ssl_tip': 'Recommend using SSL protocol.',
    'is_malicious': 'Website security score',
    'is_malicious_tip': 'Audit details available.',
    'is_brandname_in_domain': 'Is the brand name keyword in the domain?',
    'page_speed_score': 'PageSpeed score',
    'page_speed_score_tip': 'Audit details: PageSpeed.',
    'is_mobile_friendly': 'Mobile device friendliness',
    'is_mobile_friendly_tip': 'Audit details: \'Mobile friendliness\'.',
    'visit_seconds_avg': 'Is the product page accessible?',
    'visit_seconds_avg_tip': 'For products submitted to shopping ads and non-paid product details, all corresponding landing pages must be accessible to users shopping from any location.',
    'testmysite_3g_speed': '3G mobile loading speed (seconds)',
    'testmysite_4g_speed': '4G mobile loading speed (seconds)',
    'has_hp_html_error': 'Does the website have HTML or JS code errors?',
    'has_hp_html_error_tip': 'Error details can be found in the report appendix.',
    't_review_score': 'Trustpilot score',
    'r_review_rating': 'ResellerRatings score',
    's_review_rating': 'Sitejabber score',
    'is_reachable': 'Is the landing page accessible?',
    'is_reachable_tip': 'For products submitted to shopping ads and non-paid product details, all corresponding landing pages must be accessible to users shopping from any location.',
    'outgong_links_rate': 'Percentage of outbound links on the landing page',
    'outgong_links_rate_tip': 'Self-check recommended. The website should provide unique content and avoid excessive ads and external links.',
    'has_dangerous_content': 'Risk of dangerous product content',
    'has_dangerous_content_tip': 'Dangerous product content detected on the website.',
    'has_dishonest_content': 'Risk of facilitating dishonest behavior through products',
    'has_dishonest_content_tip': 'Products facilitating dishonest behavior detected on the website.',
    'has_unsupported_content': 'Unsupported shopping ad content',
    'has_unsupported_content_tip': 'Unsupported shopping ad content detected on the website.',
    'has_adult_content': 'Adult-oriented content',
    'has_adult_content_tip': 'Adult-oriented content detected on the website.',
    'has_copyrighted_content': 'Copyrighted content detected',
    'has_copyrighted_content_tip': 'Copyrighted content detected on the website.',
    'has_healthcare_medicines_content': 'Content related to healthcare and medicines',
    'has_healthcare_medicines_content_tip': 'Content related to healthcare and medicines detected on the website.',
    'has_political_content': 'Political content',
    'has_political_content_tip': 'Political content detected on the website.',
    'has_gambling_content': 'Content related to gambling',
    'has_gambling_content_tip': 'Content related to gambling detected on the website.',
    'has_unsafe_server_data': 'Risk of obtaining data through insecure server connections',
    'has_unsafe_server_data_tip': 'When handling customer\'s personal information, it is essential to use secure servers for processing.',
    'outgong_links_sum': 'Total number of outbound links on the landing page',
    'is_safe': 'Safe',
    'is_unsafe': 'Unsafe',
    'is_friendly': 'Friendly',
    'is_unfriendly': 'Unfriendly',
    'none': 'None',
    'has_error': 'Exists issue',
    'has_no_error': 'Normal',
    'sensitive_words_not_detected': 'No sensitive words detected',
    'sensitive_words_detected': 'Sensitive words detected'
  },
  checklist: {
    'title': 'Online Inspection Checklist',
    'tip': 'Please check if the following items meet the requirements, and confirm by checking before submission.',
    'checklist': [
      'Contact information must provide at least two methods, and the email must be an official email with the company or brand name suffix.',
      'The payment methods described in text must correspond one-to-one with the payment icons at the bottom of the website.',
      'The countries and regions available for delivery must be clear, avoiding terms like etc.',
      'It must be clearly stated who bears the shipping costs for returns and exchanges, the merchant or the user.',
      'The refund processing time must specify a range of days.',
      'No false reviews.',
      'No IP address restrictions.',
      'No different content displayed to users from different IP addresses.',
      'No plagiarized image materials.',
      'No products with crossed-out prices for direct discounts (if it is a new site).',
    ]
  }
};
