export default {
  'btn_login': '登录',
  'btn_change_password': '修改密码',
  'btn_signout': '退出登录',
  'btn_submit': '提交',
  'btn_send_verification_code': '发送验证码',
  'btn_delete': '删除',
  'btn_detail': '详情',
  'btn_edit': '编辑',
  'btn_migrate': '迁移',
  'btn_back': '返回',
  'btn_save_and_continue': '继续',
  'btn_install': '安装主题',
  'btn_view_demo': '查看演示商店',
  'btn_view_next': '查看下一步',
  'btn_save': '保存',
  'btn_cancel': '取消',
  'btn_confirm': '确认',
  'btn_finish': '完成',
  'btn_download_theme': '下载主题',
  'btn_nav_to_install': '去安装',
  'btn_update': '更新信息',
  'btn_add': '添加',
  btnExport: '导出',
  'btn_copy_link': '复制链接',
  frontend_link_tip: '此链接<strong>24小时内</strong>有效',
  'on': '开',
  'off': '关',
  'other': '其他',
  'upload': '上传',
  'btn_prev_step': '上一步',
  'btn_next_step': '下一步',
  'polling_state': '处理完毕页面会自动更新',
  'learn_more': '了解更多',
  'btn_filter': '筛选',
  'btn_reset': '重置',
  'btn_auth': '去授权',
  'btn_upload_file': '上传文件',
  'btn_download_template': '下载模板',
  'btn_download_theme_images': '下载主题预览',
  'waiting': '正在处理，请耐心等待...',
  'waiting_minutes': '正在处理，大概需要{minutes}分钟，请耐心等待...',
  'menu': require('./menu').default,
  'constant': require('./constant').default,
  'form': require('./form').default,
  'table': require('./table').default,
  'form_validate': require('./form-validate').default,
  'notification': require('./notification').default,
  'message_box': require('./message-box').default,
  'login': require('./login').default,
  'change_password': require('./change-password').default,
  'reset_password': require('./reset-password').default,
  'my_profile': require('./my-profile').default,
  'merchant': require('./merchant').default,
  'merchant_metaobject': require('./merchant-metaobject').default,
  'merchant_index': require('./merchant-index').default,
  'merchant_detail': require('./merchant-detail').default,
  'merchant_edit': require('./merchant-edit').default,
  'merchant_form': require('./merchant-form').default,
  'agency_index': require('./agency-index').default,
  'agency_detail': require('./agency-detail').default,
  'agency': require('./agency').default,
  'policy_template': require('./policy-template').default,
  'policy_template_index': require('./policy-template-index').default,
  'policy_template_edit': require('./policy-template-edit').default,
  'store_migration': require('./store-migration').default,
  'google_integration': require('./google-integration').default,
  'auth-amazon': require('./auth-amazon').default,
  'auth-success': require('./auth-success').default,
  'migration_tasks': require('./migration-tasks').default,
  'appeal_records': require('./appeal-records').default,
  'appeal_letters': require('./appeal-letters').default,
  'upload_file': require('./upload-file').default,
  'gads': require('./gads').default,
  'auth_google': require('./auth-google').default,
  'reviews': require('./reviews').default,
  'auth_shopify': require('./auth-shopify').default,
  'arits': require('./arits').default,
  'frontend': require('./frontend').default,
  backupInputTitle: '备选',
  blog: require('./blog').default,
  tamar_ai_account: require('./tamar-ai-account').default,
};
