import { BaseType, getBaseData } from './base';
import { POLICY_TYPE } from '@/constants';

export interface PolicyTemplateType extends BaseType {
  name: string;
  agency_id: number;
  policy_type: keyof typeof POLICY_TYPE;
  content?: string;
  abilities?: {
    destroy: boolean;
    update: boolean;
  };
}

export function getPolicyTemplateDefaultData(): PolicyTemplateType {
  return {
    ...getBaseData(),
    name: '',
    agency_id: 0,
    policy_type: 'privacy_policy',
    content: ''
  };
}
