export default {
  'btn_login': 'Login',
  'btn_change_password': 'Change Password',
  'btn_signout': 'Sign out',
  'btn_submit': 'Submit',
  'btn_send_verification_code': 'Send Verification Code',
  'btn_delete': 'Remove',
  'btn_detail': 'Detail',
  'btn_edit': 'Edit',
  'btn_migrate': 'Migrate',
  'btn_back': 'Back',
  'btn_save_and_continue': 'Save and Continue',
  'btn_install': 'Install',
  'btn_view_demo': 'View Demo Store',
  'btn_view_next': 'View Next Step',
  'btn_save': 'Save',
  'btn_cancel': 'Cancel',
  'btn_confirm': 'Confirm',
  'btn_finish': 'Finish',
  'btn_download_theme': 'Download Theme',
  'btn_nav_to_install': 'Take me to installation page',
  'btn_update': 'Update',
  'btn_add': 'Add',
  btnExport: 'Export',
  'btn_copy_link': 'Copy Link',
  frontend_link_tip: 'This link is <strong>valid for 24 hours</strong>',
  'on': 'On',
  'off': 'Off',
  'other': 'Other',
  'upload': 'Upload',
  'btn_prev_step': 'Prev Step',
  'btn_next_step': 'Next Step',
  'polling_state': 'The page will be automatically updated after processing',
  'learn_more': 'Learn more',
  'btn_filter': 'Filter',
  'btn_reset': 'Reset',
  'btn_auth': 'Auth',
  'btn_upload_file': 'Upload',
  'btn_download_template': 'Download template',
  'btn_download_theme_images': 'Download theme preview',
  'waiting': 'Processing, please wait...',
  'waiting_minutes': 'Processing, it will take about {minutes} minutes, please wait...',
  'menu': require('./menu').default,
  'constant': require('./constant').default,
  'form': require('./form').default,
  'table': require('./table').default,
  'form_validate': require('./form-validate').default,
  'notification': require('./notification').default,
  'message_box': require('./message-box').default,
  'login': require('./login').default,
  'change_password': require('./change-password').default,
  'reset_password': require('./reset-password').default,
  'my_profile': require('./my-profile').default,
  'merchant': require('./merchant').default,
  'merchant_metaobject': require('./merchant-metaobject').default,
  'merchant_index': require('./merchant-index').default,
  'merchant_detail': require('./merchant-detail').default,
  'merchant_edit': require('./merchant-edit').default,
  'merchant_form': require('./merchant-form').default,
  'agency_index': require('./agency-index').default,
  'agency_detail': require('./agency-detail').default,
  'agency': require('./agency').default,
  'policy_template': require('./policy-template').default,
  'policy_template_index': require('./policy-template-index').default,
  'policy_template_edit': require('./policy-template-edit').default,
  'store_migration': require('./store-migration').default,
  'google_integration': require('./google-integration').default,
  'auth-amazon': require('./auth-amazon').default,
  'auth-success': require('./auth-success').default,
  'migration_tasks': require('./migration-tasks').default,
  'appeal_records': require('./appeal-records').default,
  'appeal_letters': require('./appeal-letters').default,
  'upload_file': require('./upload-file').default,
  'gads': require('./gads').default,
  'auth_google': require('./auth-google').default,
  'reviews': require('./reviews').default,
  'auth_shopify': require('./auth-shopify').default,
  'arits': require('./arits').default,
  'frontend': require('./frontend').default,
  backupInputTitle: 'Alternative',
  blog: require('./blog').default,
  tamar_ai_account: require('./tamar-ai-account').default,
};
