export default {
  'require': '{name} is required.',
  'upload_require': 'Please upload {name}',
  'invalid_format': '{name} is invalid.',
  'min_length': '{name} at least {num} characters.',
  'max_length': '{name} can be up to {num} characters',
  'required': 'Required',
  'en_address': 'Please fill in the English address (support numbers, upper and lower case letters, spaces, " . ", " \' ", " - " )',
  'en_name': 'Incorrect format (support for upper and lower case letters, spaces, at least 2 characters)',
  'maximum_upload_file_count': 'You can upload a maximum of {count} files.',
  'upload_file_size_invalid': 'The file - {filename} cannot exceed {size} in size.',
  'url_is_invalid': 'Please enter a URL starting with http or https.',
  'email_is_invalid': 'Please enter a valid email address.',
  'image_size_too_small': 'The image - {filename} cannot be smaller than {minWidth}x{minHeight} in size.',
};
