export default {
  'merchant': {
    'form': {
      'title': 'Merchant Information'
    },
    'result': {
      'title': 'Information Updated',
      'back': 'Return to continue filling',
    }
  },
  'tamar_ai_account': {
    'yes': 'Yes',
    'no': 'No',
    'create_confirm': {
      'title': 'Amazon Authorization',
      'description': 'Do you have an Amazon account?',
    },
    'update_confirm': {
      'title': 'Amazon Authorization',
      'description': 'Do you need to update the Amazon authorization?',
    }
  }
};
